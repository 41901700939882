import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import ServiceDetails from '../components/Service/ServiceDetails';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';

const Faq = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Кейс" Pdescription="«Система оповещения о разрыве кабеля аварийной связи «FieldSense»"/>
            <section className="service_details_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 pr_70">
                        <div className="job_info">
                            <div className="info_head">
                                <i className="ti-receipt"></i>
                                <h6 className="f_p f_600 f_size_18 t_color3">Данные кейса</h6>
                            </div>
                            <div className="info_item">
                                <h6>Заказчик:</h6>
                                <p>МРС-К</p>
                            </div>
                            <div className="info_item">
                                <h6>Технология:</h6>
                                <p>LoRa, FieldSense</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="details_content">
                            <div className="sec_title">
                                <p className="f_400 f_size_15">
Разработали и развернули систему беспроводной диагностики кабеля аварийной связи и оповещения о разрывах кабеля.<br/><br/>
<ul><li>Cистема «Field Sense» предназначена для диагностики длинных кабельных линий. Локализация места обрыва кабеля передающей антенны производится по регистрации электрического поля с точностью до расстояния между датчиками.</li>
<li>«Field Sense» основана на использовании датчиков электрического поля (DKC-21), которые располагаются в контрольных точках и обеспечивают мониторинг целостности участка между двумя ближайшими узлами сети.</li>
<li>Датчики связаны между собой посредством беспроводного радиоинтерфейса LoRa с реализацией уникального, специально разработанного протокола обмена данными между узлами сети, что позволяет осуществлять контроль состояния протяженных инженерных сетей (не только кабельных линий, линий аварийной связи в подземных горных выработках, но также и трубопроводов и пр.), имеющих разветвленную структуру.</li>
<li>Сеть строится по древовидной топологии с малым количеством ветвей. В корне дерева располагается базовая станция. Датчики являются узлами сети для передачи и ретрансляции данных.</li>
<li>Помимо датчиков поля DKC-21 в системе «Field Sense» могут функционировать и другие сенсоры (вибрационные, вихретоковые, ультразвуковые, давления, температуры и т.п.), что позволяет производить диагностику протяженных инженерных коммуникаций в онлайн-режиме.</li>
<li>Легко интегрируется в существующие информационные системы предприятия.</li>
</ul>
<div className="sec_title"><p className="f_600 f_size_18">Схема работы системы FieldSense</p></div>
<img className="w-100" src="/mrsk-2.png" alt="Схема работы системы FieldSense"/>
<br /><br />
<div className="sec_title"><p className="f_600 f_size_18">Состав системы</p></div>
<ul>
<li>терминальная станция «LoRa-WiFi (DKT-21)»;</li>
<li>ПО «АРМ-диспетчера» (разрабатывается под индивидуальные требования заказчика);</li>
<li>датчик контроля целостности DKC-21(или любой другой сенсор под требования заказчика) с беспроводным модулем LoRa. </li> 
</ul>
<div className="sec_title"><p className="f_600 f_size_18">Расположение устройств FieldSense</p></div>
<img className="w-100" src="/mrsk-1.png" alt="Система FieldSense"/>
<br /><br />
<div className="sec_title"><p className="f_600 f_size_18">Особенности системы</p></div>
<ul>
<li>Система работает в разрешенном радиодиапазоне LoRa</li>
<li>Датчики системы «Field Sense» удовлетворяют требованиям защиты IP67 и способны функционировать в температурном диапазоне от -40 °С до +70 °С, при влажности 100%</li>
<li>Важным преимуществом системы «Field Sense» является автономный срок службы датчиков до замены батареи – не менее 2-х лет с момента установки.</li>
<li>Точки установки датчиков определяются проектной документацией и зависят от требуемой точности локализации возможных дефектов на участках инженерных сетей.</li>
</ul>




                                </p>
 </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default Faq;