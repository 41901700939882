import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import ServiceDetails from '../components/Service/ServiceDetails';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';

const Faq = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Кейс" Pdescription="«Автоматическая система мониторинга насосного оборудования»"/>
            <section className="service_details_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 pr_70">
                        <div className="job_info">
                            <div className="info_head">
                                <i className="ti-receipt"></i>
                                <h6 className="f_p f_600 f_size_18 t_color3">Данные кейса</h6>
                            </div>
                            <div className="info_item">
                                <h6>Заказчик:</h6>
                                <p>Ачинский глиноземный комбинат (Rusal)</p>
                            </div>
                            <div className="info_item">
                                <h6>Технология:</h6>
                                <p>Промышленнй интернет вещей</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="details_content">
                            <div className="sec_title">
                                <p className="f_400 f_size_15">С помощью датчиков вибрации собственной разработки, компания «РТС» обеспечила на комбинате решение следующих задач:
<ul><li>Непрерывный контроль технологического и насосного оборудования</li>
<li>Автоматическое оповещение операторов о неисправностях насосного оборудования и нерегламентированных режимах работы</li>
<li>Автоматизированный и точный подсчет моточасов</li>
<li>Прогнозирование ремонтных работ</li></ul> <br /><br />
Датчики имеют полностью автономное электропитание, являются полностью беспроводными и могут быть установлены в любом, даже труднодоступном, месте, покрытом сетью LoRaWAN.<br /><br />
Сеть имеет широкую зону покрытия (до 5 километров) и обеспечивает низкие расходы на развертывание.<br /><br />
Система позволяет на ранних стадиях выявлять неисправности подшипников двигателей и цапфовых подшипников мельниц, проводить онлайн-диагностику и прогнозировать выход из строя конкретного узла оборудования..</p>
 </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default Faq;