import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import CloudBanner from '../components/Banner/CloudBanner';
import SecurityFeatures from "../components/Features/SecurityFeatures";
import Partner from '../components/Partner';
import ErpService from '../components/Service/ErpService';
import ServiceSubscribe from '../components/ServiceSubscribe';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import CloudFeaturesTwo from '../components/Features/CloudFeaturesTwo';
import Erpanalytics from '../components/Erpanalytics';

const HomeCloud = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu" hbtnClass="btn_get_radious menu_custfive"/>
            <CloudBanner/>
            <SecurityFeatures/>
            <CloudFeaturesTwo/>
            <ErpService/>
            <Erpanalytics/>
            <Partner pClass="partner_logo_area_two" pClasst="pt-0 mb-0"/>
            <ServiceSubscribe/>
            <FooterTwo fClass="pt_150" FooterData={FooterData}/>
        </div>
    )
}
export default HomeCloud;