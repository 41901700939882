import React from 'react';
import SecurityPowerfulFeaturesItem from './SecurityPowerfulFeaturesItem';

const SecurityPowerFeatures =()=>(
    <section className="security_features_area">
        <div className="container">
            <div className="hosting_title security_title text-center">
                <h2 className="wow fadeInUp" data-wow-delay="0.2s"><span>Полный цикл</span> автоматизации</h2>
            </div>
            <div className="security_features_inner">
                <SecurityPowerfulFeaturesItem image="develop.png" Title="Разработка"/>
                <SecurityPowerfulFeaturesItem image="production.png" Title="Производство"/>
                <SecurityPowerfulFeaturesItem image="integration.png" Title="Внедрение"/>
            </div>
        </div>
    </section>
)

export default SecurityPowerFeatures;