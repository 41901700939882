import React from 'react';
import Sectitle from '../Title/Sectitle';
import ErpServiceitem from './ErpServiceitem';

const ErpService =()=> {
    return(
        <section className="erp_service_area sec_pad">
            <div className="container">
                <Sectitle sClass="hosting_title erp_title text-center" Title='Выгоды сотрудничества с нами' TitleP='Подходим индивидуально к решению, тщательно следим за качеством наших проектов и соблюдением сроков.'/>
                <div className="row">
                    <ErpServiceitem itemClass="pr_70" sTitle="Безопасность труда и производства" pText="Наши решения снижают риск аварийных ситуаций и несчастных случаев" icon="erp_icon1.png"/>
                    <ErpServiceitem itemClass="pr_70 pl_10" sTitle="Оптимизация процессов производства" pText="С помощью автоматизации вы сможете сбалансировать процессы производства, выявить проблемные зоны и улучшить их работу." icon="erp_icon6.png"/>
                    <ErpServiceitem itemClass="pl_70" sTitle="Повышение темпа и объема производства" pText="Оптимальная настройка процессов позволяет нарастить темп и объем производства без дополнительных мощностей" icon="erp_icon5.png"/>
                    <ErpServiceitem itemClass="pr_70" sTitle="Экономия ресурсов" pText="Автоматизация производства позволяет сделать процесс ремонта предсказуемым и увеличить жизненный цикл оборудования." icon="erp_icon4.png"/>
                    <ErpServiceitem itemClass="pl_10" sTitle="Увеличение рентабельности" pText="Совокупная выгода от применения решений автоматизации может составить до 30% к рентабельности предприятия" icon="erp_icon2.png"/> 
                    <ErpServiceitem itemClass="pl_70" sTitle="Повышение производительности сотрудников" pText="Контроль исполнения обязанностей сотрудниками в автоматическом режиме повышает производительность" icon="erp_icon3.png"/>
                </div>
            </div>
        </section>
    )
}

export default ErpService;