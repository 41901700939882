import React, {Component} from 'react';

class SecuirtyServiceItem2 extends Component {
    render(){
        let{image, rClass, Title, description}= this.props;
        return(
            <div className={`row h_analytices_features_item align-items-center ${rClass}`}>
                <div className="col-lg-6">
                    <div className="h_security_img text-center">
                        <img className="wow fadeInUp" src={require("../../img/home-security/" + image)} alt=""/>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="h_analytices_content">
                        <h2 className="wow fadeInUp" data-wow-delay="0.1s">{Title}</h2>
                        <p className="wow fadeInUp" data-wow-delay="0.3s">{description}</p>
                        <ul className="list-unstyled">
                            <li className="wow fadeInUp" data-wow-delay="0.5s">Автоматизацию производственных и бизнес-процессов</li>
                            <li className="wow fadeInUp" data-wow-delay="0.5s">Разработку датчиков и устройств для нужд бизнеса</li>
                            <li className="wow fadeInUp" data-wow-delay="0.5s">Внедрение решений на основе технологий IIoT, RTLS, машинное зрение</li>
                            <li className="wow fadeInUp" data-wow-delay="0.5s">Комплексную автоматизацию предприятий</li>
                        </ul>
                    </div>
                    <div className="action_btn d-flex align-items-center mt_40 wow fadeInLeft" data-wow-delay="0.6s">
                                    <a href="/Contact" className="software_banner_btn">Получить консультацию</a>
                    </div>
                </div>
            </div>
            
        )
    }
}

export default SecuirtyServiceItem2;