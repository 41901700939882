import React, {Component} from 'react';



class CloudBanner extends Component{
    state = {
        open: false
    };
    
    onOpenModal = () => {
        this.setState(prevState => ({
            open: !prevState.open
        }));
    };
    render(){
        return(
            <section className="software_banner_area d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="software_banner_content">
                                <h2 className="f_500 f_size_50 w_color wow fadeInLeft" data-wow-delay="0.2s">Умные <span className="f_700">технологии </span> для <span className="f_700">умных </span>предприятий</h2>
                                <p className="w_color f_size_18 l_height30 mt_30 wow fadeInLeft" data-wow-delay="0.4s">Помогаем снизить операционные затраты
и сократить простои производства за счет внедрения решений и технологий промышленного интернета вещей (IIoT)</p>
                                <div className="action_btn d-flex align-items-center mt_40 wow fadeInLeft" data-wow-delay="0.6s">
                                    <a href="/Contact" className="software_banner_btn">Получить предложение</a>
                                    <div className="video_btn popup-youtube" >
                                        <a href="/rts-iot-keynote.pdf"><span className="w_color">Скачать презентацию</span></a>
                                    </div>

                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="software_img wow fadeInRight" data-wow-delay="0.2s">
                                <img src={require("../../img/home5/banner_img.png")} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default CloudBanner;