import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import Partner from '../components/Partner';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import SecurityService from "../components/Service/SecurityService";
import ServiceSubscribe from '../components/ServiceSubscribe';
import SecurityPowerFeatures from "../components/Features/SecurityPowerfulFeatures";

const About = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="О компании" Pdescription="ООО «РТС» — компания-разработчик и интегратор технологических решений для промышленных предприятий. Мы работаем с клиентами, которые понимают, что можно улучшить свои показатели производства путем внедрения умных технологий."/>
            <SecurityPowerFeatures />
            <SecurityService/>
            <ServiceSubscribe/>
            <Partner pClass="partner_logo_area_five bg_color"/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default About;