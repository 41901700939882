import React from 'react';
import Sectitle from '../Title/Sectitle';
import Teamitem from '../Team/Teamitem';
const Team =()=>{
    return(
        <section className="experts_team_area sec_pad">
            <div className="container">
            <Sectitle sClass="sec_title text-center mb_70" Title="Руководство компании" tClass="t_color3" TitleP=""/>
                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_01.jpg" memberN="Тимур Батурин" memberd="Директор" membert="Более 15 лет специализируется на радиоэлектронных системах и комплексах"/>
                    </div>                   
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_03.jpg" memberN="Петр Шаршавин" memberd="Технический директор" membert="Имеет более 10 лет опыта разработки, руководства разработкой радиосистем, программно-аппаратных комплексов"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_04.jpg" memberN="Тимур Краснов" memberd="Директор по продуктам" membert="Кандидат технических наук, автор научных статей и обладатель патентов в области цифровой обработки сигналов, систем радиосвязи и радионавигации"/>
                    </div>
                </div>
                <Sectitle sClass="sec_title text-center mb_70" Title="Команда РТС" tClass="t_color3" TitleP=""/>
                    <img src="/the_team.jpg" className="w-100" />

            </div>
        </section>
        
    )
}
export default Team;