import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import Sticky from 'react-stickynode';

class CustomNavbar extends Component {
    render() {
        var {mClass, nClass, cClass, slogo, hbtnClass} =this.props;
        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <header className="header_area">
                <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                    <div className={`container ${cClass}`}>
                        <Link className={`navbar-brand ${slogo}`} to="/">
                            <img src={require("../img/logo2.svg")} alt=""/>
                            <img src={require("../img/logo.svg")} alt="logo"/>
                        </Link>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span></span>
                                    <span></span>
                                </span>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                                <li className="nav-item dropdown submenu mega_menu mega_menu_two">
                                    <NavLink to="/" className="nav-link" role="button" >
                                        Главная
                                    </NavLink>
                                </li>
                                
                                <li className="dropdown submenu nav-item">
                                    <NavLink exact title="About" className="nav-link" to='/About'>О компании</NavLink>
                                </li>
                                <li className="dropdown submenu nav-item">
                                    <NavLink exact title="Service" className="nav-link" to='/Service'>Решения</NavLink> 
                                </li>
                                <li className="dropdown submenu nav-item">
                                    <NavLink exact title="Team" className="nav-link" to='/Team'>Команда</NavLink> 
                                </li>
                                <li className="dropdown submenu nav-item">
                                    <a className="nav-link" href='https://vc.ru/u/873957-rts-iot'>Блог</a> 
                                </li>

                                <li className="nav-item"><NavLink title="Contact" className="nav-link" to="/Contact">Контакты</NavLink></li>
                            </ul>
                            <a className={`btn_get btn_hover ${hbtnClass}`} href="tel:79048932526">+7 904 893-25-26</a>
                        </div>
                    </div>
                </nav>
                </header>
            </Sticky>
        );
    }
}

export default CustomNavbar;