import React, {Component} from 'react';

class SecuirtyServiceItem extends Component {
    render(){
        let{image, rClass, Title, description}= this.props;
        return(
            <div className={`row h_analytices_features_item align-items-center ${rClass}`}>
                <div className="col-lg-6">
                    <div className="h_security_img text-center">
                        <img className="wow fadeInUp" src={require("../../img/home-security/" + image)} alt=""/>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="h_analytices_content">
                        <h2 className="wow fadeInUp" data-wow-delay="0.1s">{Title}</h2>
                        <p className="wow fadeInUp" data-wow-delay="0.3s">{description}</p>
                        <ul className="list-unstyled">
                            <li className="wow fadeInUp" data-wow-delay="0.5s"><span>Промышленный интернет вещей</span><br />Умные датчики и носимые аксессуары</li>
                            <li className="wow fadeInUp" data-wow-delay="0.7s"><span>RTLS — позиционирование в реальном времени </span><br />Системы автоматизации производственной и складской логистики</li>
                            <li className="wow fadeInUp" data-wow-delay="0.9s"><span>Машинное зрение</span><br />Решения в области промышленной безопасности и охраны труда</li>
                        </ul>
                    </div>
                    <div className="action_btn d-flex align-items-center mt_40 wow fadeInLeft" data-wow-delay="0.6s">
                                    <a href="/Service" className="software_banner_btn">Посмотреть решения</a>
                    </div>
                </div>
            </div>
            
        )
    }
}

export default SecuirtyServiceItem;