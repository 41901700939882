import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import ServiceDetails from '../components/Service/ServiceDetails';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';

const Faq = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Кейс" Pdescription="«Система позиционирования в реальном времени»"/>
            <section className="service_details_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 pr_70">
                        <div className="job_info">
                            <div className="info_head">
                                <i className="ti-receipt"></i>
                                <h6 className="f_p f_600 f_size_18 t_color3">Данные кейса</h6>
                            </div>
                            <div className="info_item">
                                <h6>Заказчик:</h6>
                                <p>Kazakhmys (Балхашская обогатительная фабрика)</p>
                            </div>
                            <div className="info_item">
                                <h6>Технология:</h6>
                                <p>RTLS</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="details_content">
                            <div className="sec_title">
                                <p className="f_400 f_size_15">
Разработали и развернули систему позиционирования в реальном времени (RTLS) на основе UWB-технологии.<br/><br/>
Система была разработана и внедрена на производстве обогатительной фабрики (цветная металлургия). Благодаря внедрению системы, были решены следующие задачи:<br/>
<ul><li>Снизили процент работ, не добавляющих ценность</li>
<li>Обеспечили наличие данных для оценки эффективности</li>
<li>Повысили контроль за качеством работ</li></ul>
С помощью разработанных РТС персональных браслетов и сети базовых станций, развернутой на предприятии, наш заказчик получил возможность контролировать передвижение сотрудников и исполнение ими своих обязанностей.<br/><br/>
Благодаря системе, теперь можно регистрировать факты нахождения сотрудников вне зоны ведения работ, в запрещенных и опасных зонах, что позволяет повысить эффективность и безопасность труда на предприятии.<br/><br/>
Встроенный в персональные браслеты NFC-модуль позволяет:<ul>
<li>Контролировать доступ к оборудованию отбора и подготовки проб</li>
<li>Управлять доступом и допусками на выполнение технологических операций.</li></ul>
Мы помогли заказчику оценить эффективность работы персонала, внедрить систему KPI и усовершенствовать организацию труда в целом.
                                </p>
 </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default Faq;