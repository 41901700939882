import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
/*------ Pages-----

import DigitalMarketing from "./Pages/Digital-marketing";
import Portfolio2col from "./Pages/Portfolio-2col";
import Portfolio3col from "./Pages/Portfolio-3col";
import Portfoliofull4col from "./Pages/Portfolio-fullwidth-4col";
import PortfolioSingle from "./Pages/PortfolioSingle";
import BlogSingle from "./Pages/BlogSingle";
import Landing from "./Pages/Landing";
import homesupport from "./Pages/home-support";
import homeERP from "./Pages/Home-ERP";
import homeHosting from "./Pages/HomeHosting";
import homeSecurity from "./Pages/HomeSecurity";
import homeSoftwareDark from "./Pages/Home-software-dark";
import HomeAppShowcase from "./Pages/Home-app-showcase";
import HomeTracking from "./Pages/Home-tracking";
import HomeEvent from "./Pages/Home-event";
import HomeChat from "./Pages/Home-chat";
import Price from "./Pages/Price";
import BlogGridPage from "./Pages/BlogGridPage";
import PaymentProcessing from "./Pages/Payment-processing";
import HRManagement from "./Pages/HR-Management";
import Startup from "./Pages/Startup";
import HomeCRM from "./Pages/Home-CRM";
import Process from "./Pages/Process";
import Bloglist from "./Pages/Bloglist";
import Faq from "./Pages/Faq";
--*/

import About from "./Pages/About";
import Service from "./Pages/Service";
import Team from "./Pages/Team";
import Contact from "./Pages/Contact";
import ScrollToTopRoute from "./ScrollToTopRoute";
import HomeCloud from "./Pages/Home-cloud";
import CaseRusal from "./Pages/case-rusal";
import CaseMrsk from "./Pages/case-mrsk";
import CaseKazakhmys from "./Pages/case-kazakhmys";
import ServiceDetails from "./Pages/ServiceDetails";
import DvtDetails from "./Pages/dvt-19";
import NotFound from "./Pages/404";
import { YMInitializer } from 'react-yandex-metrika';

class App extends Component {
  componentDidMount() {
    this.props.hideLoader();
  }
  render() {
    return (
      <Router>
        <YMInitializer accounts={[83207257]} options={{webvisor: true}} version="2" />
        <Switch>
          <ScrollToTopRoute exact={true} path={"/"} component={HomeCloud} />
          <ScrollToTopRoute path="/case-rusal" component={CaseRusal} />
          <ScrollToTopRoute path="/case-kazakhmys" component={CaseKazakhmys} />
          <ScrollToTopRoute path="/case-mrsk" component={CaseMrsk} />
          <ScrollToTopRoute path="/About" component={About} />
          <ScrollToTopRoute path="/Service" component={Service} />
          <ScrollToTopRoute path="/Team" component={Team} />
          <ScrollToTopRoute
            path="/dvt-19"
            component={DvtDetails}
          />
          <ScrollToTopRoute path="/Contact" component={Contact} />
          <ScrollToTopRoute path="/ServiceDetails" component={ServiceDetails} />
          <ScrollToTopRoute component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;
