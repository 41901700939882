import React from 'react';
import SecurityFeaturesItem from './SecurityFeaturesItem';
import Sectitle from '../Title/Sectitle';

const SecurityFeatures =()=>{
    return(
        <section className="h_security_area">
            <div className="container">
                <div className="hosting_title security_title text-center">
                <Sectitle sClass="hosting_title erp_title text-center" Title='Решения компании РТС для промышленности' TitleP='Автоматизация - это малозатратное мероприятие, которое сможет внести существенные улучшения в работу любого предприятия'/>
                </div>
                <div className="row">
                    <SecurityFeaturesItem itemClass="pr_70" image="iiot.png" Title="Промышленный интернет вещей" pText="Беспроводные датчики, сенсоры и носимые аксессуары. Предназначены для мониторинга оборудования, контроля персонала, управления доступом и обеспечения промышленной безопасности."/>
                    <SecurityFeaturesItem itemClass="pl_70" image="rtls.png" Title="Позиционирование в реальном времени" pText="Позволяет оптимизировать складскую и производственную логистику, контролировать перемещения грузов и передвижение персонала даже в закрытых помещениях."/>
                    <SecurityFeaturesItem itemClass="pr_70" image="mv.png" Title="Машинное зрение" pText="Обеспечивает контроль соблюдения сотрудниками техники безопасности и регламентов технологических процессов, идентификацию персонала и транспорта в СКУД."/>
                    <SecurityFeaturesItem itemClass="pl_70" image="complex.png" Title="Комплексная автоматизация" pText="Автоматизация любых производственных процессов «под ключ» под запрос вашего предприятия."/>
                </div>
            </div>
        </section>
    )
}
export default SecurityFeatures;