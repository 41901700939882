import React, {Component} from 'react';
import SecurityServiceItem from './SecuirtyServiceItem';
import SecurityServiceItem2 from './SecuirtyServiceItem2';

class SecurityService extends Component {
    render(){
        return(
            <section className="h_security_about_area">
                <div className="container">
                    <SecurityServiceItem image="about_img_2.jpg" Title="От разработки до внедрения"
                    description="В нашем арсенале — полный набор современных технологий для компонентной и комплексной автоматизации:"/>
                    <SecurityServiceItem2 image="about_img_1.jpg" rClass="flex-row-reverse" Title="Клиенты доверяют нам"
                    description="Промышленные предприятия, разработчики и интеграторы систем автоматизации доверяют нам:"/>
                </div>

            </section>
        )
    }
}

export default SecurityService;