import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import ServiceDetails from '../components/Service/ServiceDetails';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';

const Faq = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="ДВТ-19" Pdescription="Беспроводной датчик вибрации и температуры"/>
            <section className="service_details_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 pr_70">
                        <div className="job_info">
                            <div className="info_head">
                                <i className="ti-receipt"></i>
                                <h6 className="f_p f_600 f_size_18 t_color3">Данные продукта</h6>
                            </div>
                            <div className="info_item">
                                <h6>Автономная работа:</h6>
                                <p>До 5 лет</p>
                            </div>
                            <div className="info_item">
                                <h6>Дальность связи:</h6>
                                <p>До 5 километров</p>
                            </div>
                            <div className="info_item">
                                <h6>Технический паспорт:</h6>
                                <a href="/dvt-19.pdf"><p>Скачать →</p></a>
                            </div>
                            <div className="info_item">
                                <h6>Презентация:</h6>
                                <a href="/vibro-iot.pdf"> <p>Скачать →</p></a>
                            </div>
                            <div className="info_item">
                                <h6>Стоимость:</h6>
                                <a href="/Contact"><p>Запросить →</p></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="details_content">
                            <div className="sec_title">
                                <p className="f_400 f_size_15">ДВТ-19 предназначен для мониторинга и диагностики технического состояния роторных машин, а именно для измерения вибрации и температуры не вращающихся частей оборудования. <br/><br/>Электропитание ДВТ-19 осуществляется посредством литий-тионил хлоридного источника тока, установленного внутри датчика, что делает его автономным средством мониторинга, не требующим питания от сети 220 В.</p>
                                <img className="w-100" src="/dvt-19-picture.jpg" alt="Датчик ДВТ-19"/>
                                <p className="f_400 f_size_15">Датчик позволяет получать информацию о неисправности и нерегламентированных режимах работы оборудования на ранних стадиях. Таким образом, в любом производственном цикле возможно применять систему в качестве автоматического контроля состояния оборудования.
                                </p>
                            </div>
                            <div className="sec_title"><p className="f_600 f_size_18">Схема работы датчика ДВТ-19</p></div>

                                <img className="w-100" src="/dvt-19-infographics.png" alt="Схема работы датчика ДВТ-19"/>

                        </div>
                    </div>
                </div>
            </div>
        </section>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default Faq;