import React , {Component} from 'react';
import AboutWidget from './FooterWidget/AboutWidget';
import SolutionWidget from './FooterWidget/SolutionWidget';
import TeamWidget from './FooterWidget/TeamWidget';
import Reveal from 'react-reveal/Reveal'
import Player from "../../components/Banner/videobtn";
class FooterTwo extends Component {
    state = {
        open: false
    };
    
    onOpenModal = () => {
        this.setState(prevState => ({
            open: !prevState.open
        }));
    };
    
    render(){
        var {fClass} = this.props;
        let FooterData = this.props.FooterData;
        return(
            <footer className={`footer_area footer_area_four f_bg ${fClass}`}>
                <Player open={this.state.open} toggleModal={this.onOpenModal} />
                <div className="footer_top">
                    <div className="container">
                        <div className="row">
                            {
                                FooterData.CompanyWidget.map(widget=>{
                                    return(
                                        <Reveal effect="fadeInUp" key={widget.id}>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="f_widget company_widget wow fadeInLeft" data-wow-delay="0.2s">
                                                <a href="index.html" className="f-logo"><img src={require('../../img/logo.svg')} alt=""/></a>
                                                    <div className="widget-wrap">
                                                        <p className="f_400 f_p f_size_15 mb-0 l_height34"><span>Почта:</span> <a href="mailto:mail@rts-iot.ru" className="f_400">mail@rts-iot.ru</a></p>
                                                        <p className="f_400 f_p f_size_15 mb-0 l_height34"><span>Телефон:</span> <a href="tel:79048932526" className="f_400">+7 904 893-25-26</a></p>
                                                    </div><hr />
                                                    <a href="https://sk.ru/" className="f-logo"><img src={require('../../img/sk_logo.png')} alt=""/></a><br/><br/>
                                                    <a href="https://www.sewio.net/" className="f-logo"><img src={require('../../img/Sewio_Logo_Master_2018_1.svg')} alt=""/></a><br/><br/>
                                                    <a href="https://vizorlabs.ru/" className="f-logo"><img src={require('../../img/logo_vizorlabs.svg')} alt=""/></a>
                                                </div>
                                            </div>
                                        </Reveal>
                                    )
                                })
                            }
                            <AboutWidget ftitle="Меню"  FooterData={FooterData}/>
                            <SolutionWidget ftitle="Решения" FooterData={FooterData}/>
                            <TeamWidget ftitle="Кейсы" FooterData={FooterData}/>
                        </div>
                    </div>
                </div>
                <div className="footer_bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-5 col-sm-6">
                                <p className="mb-0 f_400">{FooterData.copywrite}</p>
                            </div>
                            <div className="col-lg-4 col-md-3 col-sm-6">
                               {/* <div className="f_social_icon_two text-center">
                                    {
                                        FooterData.socialIcon.map(item =>{
                                            return(
                                                <a href="/" key={item.id}><i className={item.icon}></i></a>
                                            )
                                        })
                                    }
                                </div>*/}
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <ul className="list-unstyled f_menu text-right">
                                    <li><a href=".#" onClick={this.onOpenModal}>Условия</a></li>
                                    <li><a href=".#" onClick={this.onOpenModal}>Политика обработки персональных данных</a></li>
                                </ul>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default FooterTwo;