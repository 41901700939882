import React, {Component} from 'react';

class ServiceSubscribe extends Component {


  Send = async (e) => {
    e.preventDefault()
    const chat_id = '-1001527659594'
    // const parse_mode = "HTML";
    const textValue = `Имя: ${this.state.valueName}  Телефон: ${this.state.valuePhone} ` ;

    const requestOptionsPush = {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        {chat_id: chat_id, text: textValue}
      )
    };

    const res = await fetch('https://api.telegram.org/bot1789973923:AAHqyzLPjeCl51Nk0qzHKS0MOwEh4_on7jQ/sendMessage', requestOptionsPush)
    let data = null
    try {
      data = await res.json()
    } catch {
      data = await res.text()
    }
    if (res.ok) {
      console.log(data)
      alert('Заявка успешно отправлена')
    }    
    if (!res.ok) {
      console.log(data)
      alert('Что-то пошло не так, попробуйте позже')
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      valueName: '',
      valuePhone: ''
    };

    this.handleName = this.handleName.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.Send = this.Send.bind(this);
  }

  handleName(event) {
    this.setState({valueName: event.target.value});
  }

  handlePhone(event) {
    this.setState({valuePhone: event.target.value});
  }


    render(){
        let {sClass} = this.props;
        return(
            <div className="container">
                 <div className={`subscribe_form_info text-center ${sClass}`}>
                     <h2 className="f_600 f_size_30 l_height30 t_color3 mb_50">Получите бесплатную консультацию по решениям для промышленности</h2>
                     <form onSubmit={this.Send} className="subscribe-form">
                         <input type="text" className="form-control" placeholder="Имя" value={this.state.valueName} onChange={this.handleName} />
                         <input type="text" className="form-control" placeholder="Телефон" value={this.state.valuePhone} onChange={this.handlePhone} />
                         <button type="submit" className="btn_hover btn_four mt_40">Оставить заявку</button>
                         
                     </form>
                 </div>
            </div>
         )
    }
}
export default ServiceSubscribe;