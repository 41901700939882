const ServiceData = {
    STitle: 'You SaasLand wherever your business agency',
    Service: [
        {
            id : 1,
            shape : 'icon_shape1.png',
            icon : 'ti-panel',
            text : 'Датчик вибрации и температуры ДВТ-19',
            description: 'Предназначен для мониторинга и контроля режимов работы оборудования. Снижает затраты на обслуживание, увеличивает жизненный цикл оборудования.',
            btn: 'ДВТ-19',
            bicon: 'ti-arrow-right',
        },
        {
            id : 2,
            shape : 'icon_shape2.png',
            icon : 'ti-layout-grid2',
            text : 'Персональный браслет работника',
            description: 'Разработан для контроля за перемещениями сотрудников, организации системы контроля и управления доступом, обеспечения безопасности персонала и предотвращения несчастных случаев на производстве.',
            btn: 'ПБР-21',
            bicon: 'ti-arrow-right',
        },
        {
            id : 3,
            shape : 'icon_shape3.png',
            icon : 'ti-gallery',
            text : 'Prototyping',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        },
        {
            id : 4,
            shape : 'icon_shape4.png',
            icon : 'ti-fullscreen',
            text : 'Pixel Precision',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        }
    ],
    agtext1: 'Why I say old chap that is spiffing bodge, blag pardon.',
    agtext2: 'Why I say old chap that is spiffing bodge, blag pardon.',
    agtext3: 'Starkers dropped a clanger lurgy is cack excuse my French what.',
    pServiceTitle: 'Our Services',
    pServiceDetails: 'Why I say old chap that is, spiffing jolly good a load of old tosh spend a penny tosser arse over.!',
    PaymentService: [
        {
            id: 1,
            image: 'icon4.png',
            title: 'Safe1r',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 2,
            image: 'icon7.png',
            title: 'Flex1ible',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 3,
            image: 'icon5.png',
            title: 'Conv3inient',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 4,
            image: 'icon8.png',
            title: 'Protected',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 5,
            image: 'icon6.png',
            title: 'World wide',
            description: 'Well at public scho cheeky bugger grub burke.'
        }
    ],
    HRService: [
        {
            id: '1',
            HRtitles : 'Датчик вибрации и температуры ДВТ-19',
            HRdescription: 'Предназначен для мониторинга и контроля режимов работы оборудования. Повышает безопасность, увеличивает жизненный цикл оборудования.',
            Hicon: 'panel',
            rclass : 'pr_70',
            iclass: 'icon_one',
            url: 'dvt-19',
            btntext: 'Датчик ДВТ-19 →',
        },
        {
            id: '2',
            HRtitles : 'Персональный браслет работника',
            HRdescription: 'Разработан для контроля за перемещениями сотрудников, организации системы контроля и управления доступом, обеспечения безопасности персонала и предотвращения несчастных случаев на производстве..',
            Hicon: 'layout-grid2',
            rclass : 'pl_20 pr_20',
            iclass: 'icon_two',
            url: '/Contact',
            btntext: 'Запросить презентацию →',
        },
        {
            id: '3',
            HRtitles : 'Система позинионирования в реальном времени',
            HRdescription: 'Позволяет оптимизировать складскую и производственную логистику, контролировать перемещения грузов и передвижение персонала даже в закрытых помещениях.',
            Hicon: 'location-pin',
            rclass : 'pr_70',
            iclass: 'icon_three',
            url: '/rtls-keynote.pdf',
            btntext: 'Скачать презентацию →',
        },
        {
            id: '4',
            HRtitles : 'Комплекс «Машинное зрение»',
            HRdescription: 'Обеспечивает контроль соблюдения сотрудниками техники безопасности и регламентов технологических процессов, идентификацию персонала и транспорта в СКУД.',
            Hicon: 'fullscreen',
            rclass : 'pr_70',
            iclass: 'icon_four',
            url: '/mvision-keynote.pdf',
            btntext: 'Скачать презентацию →',
        },
        {
            id: '5',
            HRtitles : 'Мониторинг ходовой части ТС',
            HRdescription: 'Позволяет сократить затраты на шины и топливо автопоездов',
            Hicon: 'car',
            rclass : 'pl_20 pr_50',
            iclass: 'icon_five',
            url: 'transport-keynote.pdf',
            btntext: 'Скачать презентацию →',
        },
        {
            id: '6',
            HRtitles : 'Комплексная автоматизация',
            HRdescription: 'Автоматизация любых производственных процессов «под ключ» под запрос вашего предприятия.',
            Hicon: 'vector',
            rclass : 'pr_70',
            iclass: 'icon_six',
            url: '/Contact',
            btntext: 'Получить консультацию →',
        }
    ],
    rpost:[
        {
            id: '01',
            image: 'blog-grid/post_1.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'March 1, 2019'
        },
        {
            id: '02',
            image: 'blog-grid/post_2.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'March 10, 2019'
        },
        {
            id: '03',
            image: 'blog-grid/post_3.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'jan 10, 2019'
        },
        {
            id: '04',
            image: 'blog-grid/post_4.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'March 1, 2019'
        }
    ],
    Blist: [
        {
            id: '01',
            image: 'blog-grid/blog_list1.jpg',
            btitle: 'Why I say old chap that is, spiffing jolly good.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '10',
            Month: 'March'
        },
        {
            id: '02',
            image: 'blog-grid/blog_list2.jpg',
            btitle: 'Why I say old chap that is, spiffing jolly good.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '10',
            Month: 'June'
        },
        {
            id: '03',
            image: 'blog-grid/blog_list3.jpg',
            btitle: 'Why I say old chap that is, spiffing jolly good.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '10',
            Month: 'March'
        },
        {
            id: '04',
            image: 'blog-grid/blog_list4.jpg',
            btitle: 'Bloke cracking goal the full monty get stuffed mate posh.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '01',
            Month: 'July'
        },
    ],
    work: [
        {
            id: '01',
            icon: 'ti-settings one',
            title: 'Premium plugins',
            p: "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!",
        },
        {
            id: '02',
            icon: 'ti-heart-broken two',
            title: 'Premium plugins',
            p: "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!",
        },
        {
            id: '03',
            icon: 'ti-target  three',
            title: 'Live page builder',
            p: "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!",
        }
    ]
}
export default ServiceData;